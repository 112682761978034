import React from 'react'
import Button from '@mui/material/Button'
import { Box, CircularProgress } from '@mui/material'
import PropTypes from 'prop-types'

const LoadingButton = ({ loading, ...props }) => {
  return (
    <Box sx={{ position: 'relative' }}>
      <Button disabled={loading} {...props}>
        {props.children}
      </Button>
      {loading && <CircularProgress size={24} sx={{ position: 'absolute', top: '50%', left: '50%' }} />}
    </Box>
  )
}

LoadingButton.propTypes = {
  loading: PropTypes.bool,
  children: PropTypes.element.isRequired,
}

LoadingButton.defaultProps = {
  loading: false,
}

export default LoadingButton

import createSvgIcon from '@mui/material/utils/createSvgIcon'

const ShieldWarning = createSvgIcon(
  <svg xmlns="http://www.w3.org/2000/svg" width="17" height="19" viewBox="0 0 17 19" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.63371 0.72496L2.09923 2.83681C0.787634 3.44766 -0.0779129 4.81564 0.00554862 6.31714C0.33184 12.1873 1.97226 14.7715 6.23483 17.7955C7.36404 18.5966 8.85251 18.5986 9.98088 17.7962C14.2566 14.7557 15.8383 12.1349 16.1932 6.33747C16.2858 4.82604 15.4188 3.44355 14.0981 2.82843L9.5816 0.724959C8.64322 0.287928 7.57209 0.287929 6.63371 0.72496ZM7.20003 13.8972C7.20003 13.4001 7.60297 12.9972 8.10003 12.9972C8.59708 12.9972 9.00003 13.4001 9.00003 13.8972C9.00003 14.3942 8.59708 14.7972 8.10003 14.7972C7.60297 14.7972 7.20003 14.3942 7.20003 13.8972ZM7.42503 11.1972C7.42503 11.57 7.72723 11.8722 8.10003 11.8722C8.47282 11.8722 8.77503 11.57 8.77503 11.1972L8.77503 4.89719C8.77503 4.52439 8.47282 4.22219 8.10003 4.22219C7.72723 4.22219 7.42503 4.52439 7.42503 4.89719L7.42503 11.1972Z"
      fill="#DDB900"
    />
  </svg>,
  'ShieldWarning'
)

export default ShieldWarning

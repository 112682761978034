import createSvgIcon from '@mui/material/utils/createSvgIcon'

const Loading = createSvgIcon(
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="18" viewBox="0 0 16 18" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.00056 3.59997C8.99021 3.59997 9.79248 2.79408 9.79248 1.79997C9.79248 0.805857 8.99021 -3.05176e-05 8.00056 -3.05176e-05C7.01091 -3.05176e-05 6.20864 0.805857 6.20864 1.79997C6.20864 2.79408 7.01091 3.59997 8.00056 3.59997ZM8.00056 18C8.99021 18 9.79248 17.1941 9.79248 16.2C9.79248 15.2059 8.99021 14.4 8.00056 14.4C7.01091 14.4 6.20864 15.2059 6.20864 16.2C6.20864 17.1941 7.01091 18 8.00056 18ZM15.1031 6.95891C14.2461 7.45597 13.1501 7.161 12.6553 6.30007C12.1605 5.43914 12.4541 4.33828 13.3112 3.84122C14.1683 3.34417 15.2642 3.63914 15.759 4.50007C16.2538 5.361 15.9602 6.46186 15.1031 6.95891ZM0.240798 13.5001C0.735623 14.361 1.83154 14.656 2.68861 14.1589C3.54567 13.6619 3.83932 12.561 3.34449 11.7001C2.84967 10.8392 1.75375 10.5442 0.896686 11.0412C0.0396245 11.5383 -0.254027 12.6392 0.240798 13.5001ZM13.3116 14.1587C12.4545 13.6616 12.1609 12.5608 12.6557 11.6999C13.1505 10.8389 14.2464 10.544 15.1035 11.041C15.9606 11.5381 16.2542 12.6389 15.7594 13.4999C15.2646 14.3608 14.1686 14.6558 13.3116 14.1587ZM0.241158 4.50007C-0.253667 5.361 0.039984 6.46186 0.897046 6.95892C1.75411 7.45597 2.85003 7.161 3.34485 6.30007C3.83968 5.43915 3.54603 4.33828 2.68897 3.84123C1.8319 3.34417 0.735983 3.63914 0.241158 4.50007Z"
      fill="#DDB900"
    />
  </svg>,
  'Loading'
)

export default Loading

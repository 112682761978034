// account urls
export const account = () => '/account'
export const login = () => '/login'
export const register = () => '/register'
export const apps = () => '/apps'
export const homeBase = () => '/'

export const torqFlow = () => 'https://app.torqflow.io/'
export const torqData = () => 'https://intellibridge.budibase.app/app/torqdata'

export const torqNLP = () => 'https://app.torqnlp.ai/'
export const torqAnalytics = () => 'https://nato.torqanalytics.io/'
export const torqSecure = () => 'https://app.torqsecure.io/'
export const torqAgent = () => 'https://agent.torqcloud.io/'
export const torqCloud = () => 'https://app.torqcloud.io/'

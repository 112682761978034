import PropTypes from 'prop-types'
import { Outlet } from 'react-router-dom'
import { experimentalStyled } from '@mui/material/styles'
// import Footer from '../Footer';

const MainLayoutRoot = experimentalStyled('div')(({ theme }) => ({
  // backgroundColor: theme.palette.background.default,
  height: '100%',
  // paddingTop: 64
}))

const MainLayout = ({ children }) => {
  return (
    <MainLayoutRoot>
      {children || <Outlet />}
      {/* <Footer /> */}
    </MainLayoutRoot>
  )
}

MainLayout.propTypes = {
  children: PropTypes.node,
}

export default MainLayout

import merge from 'lodash/merge'
import { createTheme, responsiveFontSizes } from '@mui/material/styles'
import { COLORS, FONTS, THEMES } from '../constants/theme'
import { lightShadows, darkShadows } from './shadows'

const baseOptions = {
  direction: 'ltr',
  sidebar: {
    width: {
      xs: '100%',
      md: 245,
    },
    iconColor: '#DDB900',
  },
  components: {
    MuiAvatar: {
      styleOverrides: {
        fallback: {
          height: '75%',
          width: '75%',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        '*': {
          boxSizing: 'border-box',
        },
        html: {
          MozOsxFontSmoothing: 'grayscale',
          WebkitFontSmoothing: 'antialiased',
          height: '100%',
          width: '100%',
        },
        body: {
          height: '100%',
        },
        '#root': {
          height: '100%',
        },
        '#nprogress .bar': {
          zIndex: '2000 !important',
        },
      },
    },
    MuiCardHeader: {
      defaultProps: {
        titleTypographyProps: {
          variant: 'h6',
        },
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          borderRadius: 3,
          overflow: 'hidden',
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: 'auto',
          marginRight: '16px',
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundImage: 'none',
        },
      },
    },
  },
  typography: {
    button: {
      fontWeight: 600,
    },
    fontFamily: FONTS.PRIMARY_TEXT,
    h1: {
      fontWeight: 600,
      fontSize: '3.5rem',
    },
    h2: {
      fontWeight: 600,
      fontSize: '3rem',
    },
    h3: {
      fontWeight: 600,
      fontSize: '2.25rem',
    },
    h4: {
      fontWeight: 600,
      fontSize: '2rem',
    },
    h5: {
      fontWeight: 600,
      fontSize: '1.5rem',
    },
    h6: {
      fontWeight: 600,
      fontSize: '1.125rem',
    },
    overline: {
      fontWeight: 600,
    },
    textColor: {
      primary: '#F1F2F3',
    },
  },
  fonts: {
    primary: FONTS.CIRCULAR_STD_MEDIUM,
    secondary: FONTS.INTER,
  },
  colors: {
    primary: '#F1F2F3',
    brightLink: '#94C7F0',
    info: '#3267E3',
    success: '#10DC78',
    danger: '#F15950',
    richBlack: '#141B29',
    neutral: COLORS.WHITE,
    slate: '#323F4B',
    yellow: '#DDB900',
    white: '#F1F2F3',
    green: '#25AA61',
    error: '#E14243',
    primaryBlack: '#000',
  },
}

const themesOptions = {
  [THEMES.LIGHT]: {
    components: {
      MuiInputBase: {
        styleOverrides: {
          input: {
            '&::placeholder': {
              opacity: 0.86,
              color: COLORS.SLATE_BLUE,
            },
          },
        },
      },
    },
    palette: {
      action: {
        active: COLORS.SLATE_GRAY,
      },
      background: {
        default: COLORS.LIGHT_GRAY,
        paper: COLORS.WHITE,
      },
      border: {
        default: 'rgba(0, 0, 0, 0.12)',
      },
      error: {
        contrastText: COLORS.WHITE,
        main: COLORS.RED,
      },
      mode: 'light',
      primary: {
        contrastText: COLORS.WHITE,
        main: COLORS.PURPLE,
      },
      success: {
        contrastText: COLORS.WHITE,
        main: COLORS.GREEN,
      },
      text: {
        primary: COLORS.DEEP_OCEAN,
        secondary: COLORS.SLATE_GRAY,
      },
      link: {
        primary: COLORS.LIGHT_BLUE,
        secondary: COLORS.SLATE_GRAY,
      },
      warning: {
        contrastText: COLORS.WHITE,
        main: COLORS.ORANGE,
      },
    },
    shadows: lightShadows,
  },
  [THEMES.DARK]: {
    components: {
      MuiPaper: {
        styleOverrides: {
          root: {
            backgroundColor: COLORS.CHARCOAL_GRAY,
            border: '1px solid rgba(145, 158, 171, 0.24)',
          },
        },
      },
      MuiTableCell: {
        styleOverrides: {
          root: {
            borderBottom: '1px solid rgba(145, 158, 171, 0.24)',
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          input: {
            '&:-webkit-autofill': {
              WebkitBoxShadow: '0 0 0 100px #161616 inset!important',
            },
          },
        },
      },
    },
    palette: {
      background: {
        default: COLORS.CHARCOAL,
        paper: COLORS.JET_BLACK,
      },
      divider: COLORS.FADED_STEEL_BLUE,
      error: {
        contrastText: COLORS.WHITE,
        main: COLORS.RED,
      },
      mode: 'dark',
      primary: {
        contrastText: COLORS.NERO,
        main: COLORS.BRIGHT_YELLOW,
      },
      success: {
        contrastText: COLORS.WHITE,
        main: COLORS.GREEN,
      },
      text: {
        primary: COLORS.WHITE,
        secondary: COLORS.SLATE_BLUE,
      },
      link: {
        primary: COLORS.LIGHT_BLUE,
        secondary: COLORS.SLATE_GRAY,
      },
      warning: {
        contrastText: COLORS.WHITE,
        main: COLORS.ORANGE,
      },
    },
    shadows: darkShadows,
  },
  [THEMES.NATURE]: {
    components: {
      MuiTableCell: {
        styleOverrides: {
          root: {
            borderBottom: '1px solid rgba(145, 158, 171, 0.24)',
          },
        },
      },
    },
    palette: {
      background: {
        default: '#1c2531',
        paper: '#293142',
      },
      divider: COLORS.FADED_STEEL_BLUE,
      error: {
        contrastText: COLORS.WHITE,
        main: COLORS.RED,
      },
      mode: 'dark',
      primary: {
        contrastText: COLORS.WHITE,
        main: COLORS.JUNGLE_GREEN,
      },
      success: {
        contrastText: COLORS.WHITE,
        main: COLORS.GREEN,
      },
      text: {
        primary: COLORS.WHITE,
        secondary: COLORS.SLATE_BLUE,
      },
      link: {
        primary: COLORS.LIGHT_BLUE,
        secondary: COLORS.SLATE_GRAY,
      },
      warning: {
        contrastText: COLORS.WHITE,
        main: COLORS.ORANGE,
      },
    },
    shadows: darkShadows,
  },
}

export const createCustomTheme = (config = {}, customization = {}) => {
  let themeOptions = themesOptions[config.theme]

  if (!themeOptions) {
    console.warn(new Error(`The theme ${config.theme} is not valid`))
    themeOptions = themesOptions[THEMES.LIGHT]
  }

  let theme = createTheme(
    merge(
      {},
      { ...baseOptions, ...customization },
      themeOptions,
      {
        ...(config.roundedCorners && {
          shape: {
            borderRadius: 16,
          },
        }),
      },
      {
        direction: config.direction,
      }
    )
  )

  if (config.responsiveFontSizes) {
    theme = responsiveFontSizes(theme)
  }

  return theme
}

import { withStyles } from '@mui/styles'
import logo from '../images/torq-cloud-id.svg'

const styles = () => ({
  root: {
    width: '52px',
    height: 'auto',
  },
})

const Logo = ({ classes, ...props }) => <img src={logo} alt="TorqCloud" className={classes.root} {...props} />

export default withStyles(styles)(Logo)

import * as Yup from 'yup'
import { Formik } from 'formik'
import { Box, Button, FormHelperText, TextField } from '@mui/material'
import useAuth from '../../../hooks/useAuth'
import useMounted from '../../../hooks/useMounted'
import { useState } from 'react'
import { Auth } from 'aws-amplify'
import toast from 'react-hot-toast'
import { eventTypes } from '../../../constants/analytics'

const Login = props => {
  const { onForgotPassword, ...rest } = props
  const mounted = useMounted()
  const { changeCognitoAuthUser } = useAuth()

  const [passwordShown, setPasswordShown] = useState(false)

  // Show/Hide Password Handler
  const handleClickShowPassword = () => {
    setPasswordShown(!passwordShown)
  }

  const handleSubmit = async (values, { setErrors, setStatus, setSubmitting }) => {
    try {
      const email = values.email.toLowerCase()
      const user = await Auth.signIn(email, values.password)
      changeCognitoAuthUser(user)
      window.analytics.track(eventTypes.USER_LOGIN, {
        email,
      })
      if (mounted.current) {
        setStatus({ success: true })
        setSubmitting(false)
      }
    } catch (err) {
      toast.error(err.message)
      if (mounted.current) {
        setStatus({ success: false })
        setErrors({ submit: err.message })
        setSubmitting(false)
      }
    }
  }

  return (
    <div {...rest}>
      <Formik
        initialValues={{
          email: '',
          password: '',
          submit: '',
        }}
        validationSchema={Yup.object().shape({
          email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
          password: Yup.string().max(255).required('Password is required'),
        })}
        onSubmit={handleSubmit}
      >
        {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
          <form noValidate onSubmit={handleSubmit} {...rest}>
            <div className="form-inputs">
              <div className="form-input">
                <label>
                  Email address
                  <TextField
                    error={Boolean(touched.email && errors.email)}
                    fullWidth
                    helperText={touched.email && errors.email}
                    margin="normal"
                    name="email"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="email"
                    value={values.email}
                    variant="outlined"
                  />
                </label>
              </div>
              <div className="form-input">
                <label>
                  Password
                  <TextField
                    error={Boolean(touched.password && errors.password)}
                    fullWidth
                    helperText={touched.password && errors.password}
                    // label="Password"
                    margin="normal"
                    name="password"
                    onBlur={handleBlur}
                    onChange={handleChange('password')}
                    type={passwordShown ? 'text' : 'password'}
                    value={values.password}
                    variant="outlined"
                  />
                </label>
              </div>
            </div>

            <Box display="flex" justifyContent="end">
              <Button sx={{ alignItems: 'right' }} className="password-toggle" onClick={handleClickShowPassword}>
                {passwordShown ? 'Hide Password' : 'Show Password'}
              </Button>
            </Box>

            {errors.submit && (
              <Box sx={{ mt: 3 }}>
                <FormHelperText error>{errors.submit}</FormHelperText>
              </Box>
            )}
            <Box sx={{ mt: 3 }} gap={3}>
              <Button
                sx={{
                  color: theme => theme.colors.primary,
                }}
                disabled={isSubmitting}
                size="large"
                type="submit"
                variant="contained"
              >
                Sign in
              </Button>
            </Box>
          </form>
        )}
      </Formik>
      <Box sx={{ mt: 2 }}>
        <a onClick={() => onForgotPassword()}>Forgot Password?</a>
      </Box>
    </div>
  )
}

export default Login

import * as Yup from 'yup'
import { Formik } from 'formik'
import { Box, Button, FormHelperText, TextField, Typography } from '@mui/material'
import useAuth from '../../../hooks/useAuth'
import useMounted from '../../../hooks/useMounted'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import { Auth } from 'aws-amplify'
import toast from 'react-hot-toast'

const ForgotPassword = props => {
  const mounted = useMounted()
  const [recoveryCodeSent, setRecoveryCodeSent] = useState(false)
  const { changeCognitoAuthUser } = useAuth()

  const [passwordShown, setPasswordShown] = useState(false)

  const handleSubmit = async (values, { setErrors, setStatus, setSubmitting }) => {
    const email = values.email.toLowerCase()
    try {
      if (!recoveryCodeSent) {
        const data = await Auth.forgotPassword(email)
        setRecoveryCodeSent(true)
      } else {
        const { code, password } = values
        const data = await Auth.forgotPasswordSubmit(email, code, password)
        if (data === 'SUCCESS') {
          const user = await Auth.signIn(email, values.password)
          changeCognitoAuthUser(user)
        }
      }
      if (mounted.current) {
        setStatus({ success: true })
        setSubmitting(false)
      }
    } catch (err) {
      toast.error(err.message)
      if (mounted.current) {
        setStatus({ success: false })
        setErrors({ submit: err.message })
        setSubmitting(false)
      }
    }
  }
  const email = Yup.string().email('Must be a valid email').max(255).required('Email is required')
  const validationSchemaPreSend = Yup.object().shape({
    email,
  })
  const validationSchemaPostSend = Yup.object().shape({
    email,
    code: Yup.string().max(255).required('Code is required'),
    password: Yup.string().max(255).required('Password is required'),
    passwordRepeat: Yup.string()
      .max(255)
      .required('Password is required')
      .oneOf([Yup.ref('password'), null], 'Passwords must match'),
  })
  const validationSchema = recoveryCodeSent ? validationSchemaPostSend : validationSchemaPreSend
  return (
    <div {...props}>
      <div style={{ marginBottom: 30 }}>
        <Typography color="textPrimary" variant="p">
          This process will reset your password.
        </Typography>
      </div>

      <Formik
        initialValues={{
          email: '',
          code: '',
          password: '',
          passwordRepeat: '',
          submit: '',
        }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {formik => {
          const { errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values } = formik
          return (
            <form noValidate onSubmit={handleSubmit} {...props}>
              <div className="form-inputs">
                <div className="form-input">
                  <label>
                    Email address
                    <TextField
                      error={Boolean(touched.email && errors.email)}
                      fullWidth
                      helperText={touched.email && errors.email}
                      margin="normal"
                      name="email"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="email"
                      value={values.email}
                      variant="outlined"
                    />
                  </label>
                </div>
                {recoveryCodeSent && (
                  <>
                    <Typography>A code has been sent to your email.</Typography>
                    <div className="form-input">
                      <label>
                        Code
                        <TextField
                          error={Boolean(touched.code && errors.code)}
                          fullWidth
                          helperText={touched.code && errors.code}
                          margin="normal"
                          name="code"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          type="code"
                          value={values.code}
                          variant="outlined"
                        />
                      </label>
                    </div>
                    <div className="form-input">
                      <label>
                        New Password
                        <TextField
                          error={Boolean(touched.password && errors.password)}
                          fullWidth
                          helperText={touched.password && errors.password}
                          margin="normal"
                          name="password"
                          onBlur={handleBlur}
                          onChange={handleChange('password')}
                          type={passwordShown ? 'text' : 'password'}
                          value={values.password}
                          variant="outlined"
                        />
                      </label>
                    </div>
                    <div className="form-input">
                      <label>
                        Repeat New Password
                        <TextField
                          error={Boolean(touched.passwordRepeat && errors.passwordRepeat)}
                          fullWidth
                          helperText={touched.passwordRepeat && errors.passwordRepeat}
                          // label="Password"
                          margin="normal"
                          name="passwordRepeat"
                          onBlur={handleBlur}
                          onChange={handleChange('passwordRepeat')}
                          type={passwordShown ? 'text' : 'password'}
                          value={values.passwordRepeat}
                          variant="outlined"
                        />
                      </label>
                    </div>
                  </>
                )}
              </div>

              {errors.submit && (
                <Box sx={{ mt: 3 }}>
                  <FormHelperText error>{errors.submit}</FormHelperText>
                </Box>
              )}
              <Box sx={{ mt: 3 }} gap={3}>
                <Button
                  sx={{
                    color: theme => theme.colors.primary,
                  }}
                  disabled={isSubmitting}
                  size="large"
                  type="submit"
                  variant="contained"
                >
                  {recoveryCodeSent ? 'Reset Password' : 'Send Code'}
                </Button>
              </Box>
              <Box sx={{ mt: 2 }}>
                <Link to="/">Sign In</Link>
              </Box>
            </form>
          )
        }}
      </Formik>
    </div>
  )
}

export default ForgotPassword

import { useLayoutEffect, useState } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'
import { Box, Card, CardContent, Container, Link, Typography } from '@mui/material'
import { LoginCognito } from '../../components/authentication/login'
import Logo from '../../components/Logo'
import IBLogo from '../../components/LogoIntellibridge'
import gtm from '../../lib/gtm'
import { appConfig } from '../../config'
import { register } from '../../constants/routing'
import ForgotPassword from './ForgotPassword'
import {
  ArchAnimateContainer,
  ContentAnimateContainer,
  LogoWrapper,
  StatusAnimated,
  WrapperStyled,
} from '../../components/authentication/common'

const Login = ({ onForgotPassword }) => {
  const [showForgotPassword, setShowForgotPassword] = useState(false)
  if (!onForgotPassword) {
    onForgotPassword = () => setShowForgotPassword(true)
  }

  useLayoutEffect(() => {
    gtm.push({ event: 'page_view' })
  }, [])

  if (showForgotPassword) {
    return <ForgotPassword onLogin={() => setShowForgotPassword(false)} />
  }

  return (
    <WrapperStyled>
      <Helmet>
        <title>{`Login | ${appConfig.APP_NAME}`}</title>
      </Helmet>
      <ArchAnimateContainer />
      <ContentAnimateContainer>
        <Container
          maxWidth="sm"
          sx={{
            py: '80px',
            backgroundColor: 'primary.default',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              mb: 8,
            }}
          ></Box>
          <Card>
            <CardContent
              sx={{
                display: 'flex',
                flexDirection: 'column',
                p: 4,
              }}
            >
              <LogoWrapper>
                <RouterLink to="/">
                  <Logo className="logo" />
                </RouterLink>
              </LogoWrapper>
              <Box
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <div>
                  <Typography color="textPrimary" gutterBottom variant="h4">
                    Sign in
                  </Typography>
                  <Typography color="textPrimary" gutterBottom variant="p">
                    Access your account.
                  </Typography>
                </div>
              </Box>
              <Box
                sx={{
                  flexGrow: 1,
                  mt: 3,
                }}
              >
                <LoginCognito onForgotPassword={onForgotPassword} />
              </Box>
            </CardContent>
          </Card>
          <Box textAlign="center" p={3}>
            <Typography color="textPrimary" gutterBottom variant="p" align="center">
              Don't have an account?&nbsp;
              <Link color="textSecondary" component={RouterLink} to={register()} variant="p">
                Create your account now.
              </Link>
            </Typography>
          </Box>
          <LogoWrapper>
            <IBLogo />
          </LogoWrapper>
          <StatusAnimated />
        </Container>
      </ContentAnimateContainer>
    </WrapperStyled>
  )
}

export default Login

import createSvgIcon from '@mui/material/utils/createSvgIcon'

const Analystics = createSvgIcon(
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="19" viewBox="0 0 16 19" fill="none">
    <path
      d="M2.13524 18.1986H0.915105C0.672404 18.1986 0.439644 18.1038 0.268028 17.935C0.0964126 17.7662 0 17.5373 0 17.2986V11.8989C0 11.6602 0.0964126 11.4313 0.268028 11.2625C0.439644 11.0937 0.672404 10.9989 0.915105 10.9989H2.13524C2.37795 10.9989 2.61071 11.0937 2.78232 11.2625C2.95394 11.4313 3.05035 11.6602 3.05035 11.8989V17.2986C3.05035 17.5373 2.95394 17.7662 2.78232 17.935C2.61071 18.1038 2.37795 18.1986 2.13524 18.1986Z"
      fill="#DDB900"
    />
    <path
      d="M10.6762 18.1975H9.45606C9.21336 18.1975 8.9806 18.1027 8.80898 17.9339C8.63737 17.7652 8.54095 17.5363 8.54095 17.2976V8.29799C8.54095 8.0593 8.63737 7.83039 8.80898 7.66162C8.9806 7.49284 9.21336 7.39803 9.45606 7.39803H10.6762C10.9189 7.39803 11.1517 7.49284 11.3233 7.66162C11.4949 7.83039 11.5913 8.0593 11.5913 8.29799V17.2976C11.5913 17.5363 11.4949 17.7652 11.3233 17.9339C11.1517 18.1027 10.9189 18.1975 10.6762 18.1975Z"
      fill="#DDB900"
    />
    <path
      d="M14.9467 18.198H13.7266C13.4839 18.198 13.2511 18.1032 13.0795 17.9344C12.9079 17.7656 12.8115 17.5367 12.8115 17.298V4.09861C12.8115 3.85993 12.9079 3.63102 13.0795 3.46225C13.2511 3.29347 13.4839 3.19865 13.7266 3.19865H14.9467C15.1894 3.19865 15.4222 3.29347 15.5938 3.46225C15.7654 3.63102 15.8618 3.85993 15.8618 4.09861V17.298C15.8618 17.5367 15.7654 17.7656 15.5938 17.9344C15.4222 18.1032 15.1894 18.198 14.9467 18.198Z"
      fill="#DDB900"
    />
    <path
      d="M6.40575 18.1978H5.18561C4.94291 18.1978 4.71015 18.103 4.53854 17.9342C4.36692 17.7654 4.27051 17.5365 4.27051 17.2978V1.09854C4.27051 0.859853 4.36692 0.630945 4.53854 0.46217C4.71015 0.293395 4.94291 0.198578 5.18561 0.198578H6.40575C6.64845 0.198578 6.88121 0.293395 7.05283 0.46217C7.22445 0.630945 7.32086 0.859853 7.32086 1.09854V17.2978C7.32086 17.5365 7.22445 17.7654 7.05283 17.9342C6.88121 18.103 6.64845 18.1978 6.40575 18.1978Z"
      fill="#DDB900"
    />
  </svg>,
  'Analystics'
)

export default Analystics

import { keyframes } from '@emotion/react'
import styled from '@emotion/styled'
import { Card, Container } from '@mui/material'
import statueOfLibertySvg from '../../../images/statue-of-liberty-dark_isolated.svg'
import archSvg from '../../../images/arch-isolated.svg'
import starSvg from '../../../images/star-background.svg'
export const slideUpAnimation = keyframes`
  from {
    margin-top: 100%;
    height: 100%;
  }
  to {
    margin-top: 0%;
    height: 100%;
  }
`

export const ArchAnimateContainer = styled(Container)(({ theme }) => ({
  width: '100%',
  maxWidth: '100% !important',
  height: '100%',
  position: 'absolute',
  top: 0,
  left: 0,
  backgroundImage: `url(${archSvg})`,
  backgroundSize: 'contain',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'bottom',
  overflow: 'auto',
  animationName: slideUpAnimation,
  animationDuration: '2s',
}))

export const ContentAnimateContainer = styled(Container)(({ theme }) => ({
  width: '100%',
  maxWidth: '100% !important',
  height: '100%',
  position: 'absolute',
  top: 0,
  left: 0,
  overflow: 'auto',
  animationName: slideUpAnimation,
  animationDuration: '3s',

  '.password-toggle': {
    float: 'left',
    clear: 'both',
    zIndex: 2,
    position: 'relative',
    background: 'none !important',
    border: 'none',
    padding: 0,
  },

  'a:link, a:visited, a:active, a:hover, .password-toggle': {
    fontSize: '1em',
    fontWeight: 300,
    lineHeight: '162%' /* 25.92px */,
    textDecorationLine: 'underline',
    cursor: 'pointer',
    color: theme.palette.link.primary,
    fontFamily: theme.typography.fontFamily,
  },
}))

export const WrapperStyled = styled('div')(({ theme }) => ({
  backgroundImage: `url(${starSvg})`,
  backgroundColor: theme.palette.background.default,
  backgroundRepeat: 'repeat',
  backgroundAttachment: 'fixed',
  width: '100vw',
  height: '100vh',
}))

export const StatusAnimated = styled('div')(({ theme }) => ({
  width: '700px',
  height: '100%',
  position: 'absolute',
  top: 0,
  left: '56%',
  backgroundSize: 'contain',
  backgroundImage: `url(${statueOfLibertySvg})`,
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'bottom',
  overflow: 'hidden',
  animationName: slideUpAnimation,
  animationDuration: '2s',
  [theme.breakpoints.up('sm')]: {
    display: 'none',
  },
  [theme.breakpoints.up('md')]: {
    left: '60%',
    display: 'flex',
  },
  [theme.breakpoints.up('lg')]: {
    left: '58%',
    display: 'flex',
  },
}))

export const LogoWrapper = styled('div')(({ theme }) => ({
  width: '100%',
  margin: 'auto',
  textAlign: 'center',
  img: {
    width: '300px',
  },
}))

export const StyledCard = styled(Card)(({ theme }) => ({
  borderRadius: '16px',
  background: theme.palette.background.default,
  boxShadow: '0px 4px 48px 0px rgba(0, 0, 0, 0.15)',
  backdropFilter: 'blur(12px)',
}))

import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Box, Drawer, Typography } from '@mui/material'
import useMediaQuery from '@mui/material/useMediaQuery'
import NavSection from './NavSection'
import Scrollbar from '../Scrollbar'
import styled from '@emotion/styled'
import { COLORS } from '../../constants/theme'
import { torqCloud } from '../../constants/routing'
import CloseIcon from '@mui/icons-material/Close'

const LogoWrapper = styled(Box)`
  width: 100%;

  img {
    width: 80%;
  }
`

const DashboardSidebar = props => {
  const { onMobileClose, openMobile, sections, logoLinkUrl } = props
  const location = useLocation()
  const mediumAndUp = useMediaQuery(theme => theme.breakpoints.up('md'))

  const content = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
      }}
    >
      <Scrollbar options={{ suppressScrollX: true }}>
        <Box display="flex" justifyContent="space-between" flexDirection="column" height="100%">
          <Box display="flex" flexDirection="column">
            <Box
              sx={{
                display: {
                  xs: 'flex',
                },
                justifyContent: 'center',
                p: 2,
              }}
            ></Box>
            <LogoWrapper sx={{ p: 2, flexGrow: 1 }}>
              <a href={logoLinkUrl}>
                <img src={'/img/torq-logo.svg'} alt="TorqCloud" {...props} />
              </a>
            </LogoWrapper>
            <Box sx={{ p: 2, flexGrow: 1 }}>
              {sections.map(section => (
                <NavSection
                  key={section.title}
                  pathname={location.pathname}
                  sx={{
                    '& + &': {
                      mt: 3,
                    },
                  }}
                  {...section}
                />
              ))}
            </Box>
          </Box>
          <LogoWrapper
            sx={{
              p: 2,
              flexGrow: 1,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'end',
              gap: 1,
              paddingBottom: 5,
            }}
          >
            <img src={'/img/torq-ib-logo.svg'} alt="TorqCloud" {...props} />
            <Typography
              sx={{
                fontSize: '10px',
                color: theme => theme.typography.textColor.primary,
                textTransform: 'uppercase',
                letterSpacing: '0.4px',
              }}
            >
              Arch Product Studio
            </Typography>
          </LogoWrapper>
        </Box>
      </Scrollbar>
    </Box>
  )

  return (
    <>
      {mediumAndUp ? (
        <>
          {openMobile && <Box sx={{ minWidth: theme => theme.sidebar.width }} />}
          <Drawer
            anchor="left"
            open={openMobile}
            PaperProps={{
              sx: {
                background: COLORS.GRADIENT_BG,
                height: 'calc(100%) !important',
                width: theme => theme.sidebar.width,
                paddingLeft: '1.4rem',
                zIndex: 1,
              },
            }}
            variant={openMobile ? 'permanent' : 'temporary'}
          >
            {content}
            <CloseIcon
              onClick={() => onMobileClose()}
              sx={{
                position: 'absolute',
                top: '1rem',
                right: '1rem',
                cursor: 'pointer',
              }}
            />
          </Drawer>
        </>
      ) : (
        <Drawer
          anchor="left"
          onClose={onMobileClose}
          open={openMobile}
          PaperProps={{
            sx: {
              background: COLORS.GRADIENT_BG,
              width: theme => theme.sidebar.width,
              zIndex: 1,
            },
          }}
          variant="temporary"
        >
          {content}
          <CloseIcon
            onClick={() => onMobileClose()}
            sx={{
              position: 'absolute',
              top: '1rem',
              right: '1rem',
              cursor: 'pointer',
            }}
          />
        </Drawer>
      )}
    </>
  )
}

DashboardSidebar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
  sections: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      items: PropTypes.arrayOf(
        PropTypes.shape({
          title: PropTypes.string,
          path: PropTypes.string,
          icon: PropTypes.element,
          childItems: PropTypes.arrayOf(
            PropTypes.shape({
              title: PropTypes.string,
              path: PropTypes.string,
              icon: PropTypes.element,
            })
          ),
        })
      ),
    })
  ).isRequired,
}

export default DashboardSidebar

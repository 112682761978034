// account urls
export const account = () => '/account'
export const login = () => '/login'
export const register = () => '/register'
export const apps = () => '/apps'
export const analyticsBlueForceRedForce = () => '/analytics/blue-force-red-force'
export const analyticsUnitReadiness = () => '/analytics/unit-readiness'
export const analyticsMap = () => '/analytics/map'
export const homeBase = () => '/'

export const torqFlow = () => 'https://app.torqflow.io/'
export const torqData = () => 'https://intellibridge.budibase.app/app/torqdata'

export const torqNLP = () => 'https://app.torqnlp.ai/'
export const torqAnalytics = () => 'https://nato.torqanalytics.io/'
export const torqSecure = () => 'https://app.torqsecure.io/'
export const torqAgents = () => 'https://agent.torqcloud.io/'

// torqsecure urls
export const aOlytics = () => 'https://aolytix.aws.intellibridgelabs.io'
export const sbomSecurity = () => '/torq-secure/sbom-security'

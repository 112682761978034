import { useState, useEffect, useRef } from 'react'
import { z, ZodTypeAny } from 'zod'

// this is consistent with axios responses
export type ApiFunctionType = (...any: any[]) => Promise<{ data: unknown }>

// memoization cache
const resultsCache = new Map<string, any>()

export function useTypedApi<F extends ApiFunctionType, T extends ZodTypeAny>(
  apiFunc: F,
  args: Parameters<F>,
  schema: T,
  memoizeKey?: string
) {
  type PayloadType = z.infer<typeof schema>

  const [data, setData] = useState<PayloadType>(null)
  const [error, setError] = useState<unknown>(null)
  const [loading, setLoading] = useState(false)
  const [loaded, setLoaded] = useState(false)
  const requestInProgress = useRef(false)

  useEffect(() => {
    const cacheKey = memoizeKey && `${memoizeKey}-${JSON.stringify(args)}`
    const cachedResult = cacheKey && resultsCache.get(cacheKey)
    if (cachedResult) {
      setData(cachedResult)
      setLoaded(true)
      setLoading(false)
      return
    }

    if (!data && !requestInProgress.current) {
      requestInProgress.current = true
      setLoading(true)

      void apiFunc(...args)
        .then(result => {
          const data = schema.parse(result.data)
          setData(data)
          if (cacheKey) {
            resultsCache.set(cacheKey, data)
          }
          setLoaded(true)
        })
        .catch(err => {
          setError(err || 'Unexpected Error!')
        })
        .finally(() => {
          setLoading(false)
          requestInProgress.current = false
        })
    }
  }, [apiFunc, args, loading, memoizeKey, schema])
  return { data, error, loading, loaded }
}

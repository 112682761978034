export const THEMES = {
  LIGHT: 'LIGHT',
  DARK: 'DARK',
  NATURE: 'NATURE',
}

export const COLORS = {
  GREEN: '#4caf50',
  JUNGLE_GREEN: '#01ab56',
  PURPLE: '#5664d2',
  RED: '#f44336',
  ORANGE: '#ff9800',
  WHITE: '#ffffff',
  LIGHT_GRAY: '#f4f5f7',
  SLATE_GRAY: '#6b778c',
  CHARCOAL_GRAY: 'rgb(30, 32, 33)',
  DEEP_OCEAN: '#172b4d',
  STEEL_BLUE: '#42526e',
  SLATE_BLUE: '#919eab',
  LIGHT_BLUE: '#94C7F0',
  FADED_STEEL_BLUE: 'rgba(145, 158, 171, 0.24)',
  CHARCOAL: '#141B22',
  JET_BLACK: '#161616',
  BRIGHT_YELLOW: 'rgb(252, 212, 5)',
  GRADIENT_BG: 'linear-gradient(180deg, #313E4B 0%, #1F2934 100%)',
  NERO: '#202020',
}

export const FONTS = {
  PRIMARY_TEXT: '-apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
  CIRCULAR_STD_MEDIUM: "'Circular Std Medium', sans-serif",
  INTER: "'Inter', sans-serif",
}

import { SHOW_SIDEBAR } from './actions'

export const initialState = {
  show: true,
}

const sidebarReducer = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_SIDEBAR:
      return {
        show: action.show,
      }
    default:
      return state
  }
}

export default sidebarReducer

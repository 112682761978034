import PropTypes from 'prop-types'
import { Outlet } from 'react-router-dom'
import { experimentalStyled } from '@mui/material/styles'

const MainLayoutRoot = experimentalStyled('div')(() => ({
  height: '100%',
}))

const MainLayout = ({ children }) => {
  return <MainLayoutRoot>{children || <Outlet />}</MainLayoutRoot>
}

MainLayout.propTypes = {
  children: PropTypes.node,
}

export default MainLayout

import * as Yup from 'yup'
import { Formik } from 'formik'
import { Box, Button, Checkbox, FormHelperText, Link, TextField, Typography } from '@mui/material'
import useMounted from '../../../hooks/useMounted'
import { Auth } from 'aws-amplify'
import { useState } from 'react'
import toast from 'react-hot-toast'
import useAuth from '../../../hooks/useAuth'
import { registerUser } from '../../../api/torqCloud/user'

const ViewState = {
  REGISTRATION_FORM: 0,
  AUTO_CONFIRM: 1,
  ADMIN_CONFIRM: 2,
}

const Register = ({ torqCloudBaseUrl, ...props }) => {
  const adminConfirmMode = new Boolean(process.env?.REACT_APP_COGNITO_ADMIN_CONFIRMATION)
  const nextViewState = adminConfirmMode.valueOf() ? ViewState.ADMIN_CONFIRM : ViewState.AUTO_CONFIRM
  const mounted = useMounted()
  const [viewState, setViewState] = useState(ViewState.REGISTRATION_FORM)

  const [otpCode, setOtpCode] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const { changeCognitoAuthUser } = useAuth()

  const handleRegister = async (values, { setErrors, setStatus, setSubmitting }) => {
    try {
      const email = values.email.toLowerCase()
      const res = await Auth.signUp({
        username: email,
        password: values.password,
      })
      setEmail(email)
      setPassword(values.password)
      setViewState(nextViewState)

      registerUser(torqCloudBaseUrl, {
        email: email,
        password: values.password,
        user_id: res['userSub'],
      })

      if (mounted.current) {
        setStatus({ success: true })
        setSubmitting(false)
      }
    } catch (err) {
      toast.error(err.message)
      if (mounted.current) {
        setStatus({ success: false })
        setErrors({ submit: err.message })
        setSubmitting(false)
      }
      toast.error(`error ${err.message}`)
    }
  }

  const handleVerifyEmail = async () => {
    try {
      await Auth.confirmSignUp(email, otpCode)
      const user = await Auth.signIn(email, password)
      changeCognitoAuthUser(user)
    } catch (err) {
      toast.error(err.message)
    }
  }

  return (
    <>
      {viewState === ViewState.AUTO_CONFIRM && (
        <>
          <div className="form-input">
            <label>
              Verification code
              <TextField
                fullWidth
                margin="normal"
                name="code"
                onChange={e => setOtpCode(e.target.value)}
                type="text"
                value={otpCode}
                variant="outlined"
              />
            </label>
          </div>
          <Box sx={{ mt: 2 }}>
            <Button color="primary" fullWidth size="large" type="submit" onClick={handleVerifyEmail} variant="contained">
              Verify
            </Button>
          </Box>
        </>
      )}
      {viewState === ViewState.ADMIN_CONFIRM && (
        <Box sx={{ mt: 2, marginTop: 2, marginBottom: 2 }}>We are now reviewing your account for approval. Please hang tight.</Box>
      )}
      {viewState === ViewState.REGISTRATION_FORM && (
        <>
          <Formik
            initialValues={{
              email: '',
              password: '',
              policy: true,
              submit: null,
            }}
            validationSchema={Yup.object().shape({
              email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
              password: Yup.string().min(7).max(255).required('Password is required'),
              policy: Yup.boolean().oneOf([true], 'This field must be checked'),
            })}
            onSubmit={handleRegister}
          >
            {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
              <form noValidate onSubmit={handleSubmit} {...props}>
                <div className="form-inputs">
                  <div className="form-input">
                    <label>
                      Email address
                      <TextField
                        error={Boolean(touched.email && errors.email)}
                        fullWidth
                        helperText={touched.email && errors.email}
                        margin="normal"
                        name="email"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="email"
                        value={values.email}
                        variant="outlined"
                      />
                    </label>
                  </div>
                  <div className="form-input">
                    <label>
                      Password
                      <TextField
                        error={Boolean(touched.password && errors.password)}
                        fullWidth
                        helperText={touched.password && errors.password}
                        label="Password"
                        margin="normal"
                        name="password"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="password"
                        value={values.password}
                        variant="outlined"
                      />
                    </label>
                  </div>
                </div>
                <Box
                  sx={{
                    alignItems: 'center',
                    display: 'flex',
                    ml: -1,
                    mt: 2,
                  }}
                >
                  <Checkbox checked={values.policy} color="primary" name="policy" onChange={handleChange} />
                  <Typography color="textSecondary" variant="body2">
                    I have read the{' '}
                    <Link color="primary" component="a" href="#">
                      Terms and Conditions
                    </Link>
                  </Typography>
                </Box>
                {Boolean(touched.policy && errors.policy) && <FormHelperText error>{errors.policy}</FormHelperText>}
                {errors.submit && (
                  <Box sx={{ mt: 3 }}>
                    <FormHelperText error>{errors.submit}</FormHelperText>
                  </Box>
                )}
                <Box sx={{ mt: 2 }}>
                  <Button color="primary" disabled={isSubmitting} fullWidth size="large" type="submit" variant="contained">
                    Register
                  </Button>
                </Box>
              </form>
            )}
          </Formik>
        </>
      )}
    </>
  )
}

export default Register

import createSvgIcon from '@mui/material/utils/createSvgIcon'

const Chat = createSvgIcon(
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="17" viewBox="0 0 18 17" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.2 0.397186H8.22857C11.1404 0.397186 13.5812 2.4558 14.2306 5.22509C14.3414 5.69735 14.4 6.19028 14.4 6.69719C14.4 10.1766 11.637 12.9972 8.22857 12.9972H4.46545H2.05714C0.921014 12.9972 0 12.057 0 10.8972V7.74719C0 3.68789 3.22355 0.397186 7.2 0.397186ZM8.48571 14.7972H5.45232C6.56542 15.9106 8.08988 16.5972 9.77143 16.5972H13.5345H15.9429C17.079 16.5972 18 15.657 18 14.4972V11.3472C18 9.48166 17.3192 7.77847 16.1973 6.4824C16.1991 6.55377 16.2 6.62536 16.2 6.69718C16.2 11.1707 12.7462 14.7972 8.48571 14.7972Z"
      fill="#DDB900"
    />
  </svg>,
  'Chat'
)

export default Chat

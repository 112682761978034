import Login from '../pages/authentication/Login'
import ForgotPassword from '../pages/authentication/ForgotPassword'
import GuestGuard from '../components/GuestGuard'

export const DEFAULT_ROUTES = [
  {
    path: 'login',
    element: (
      <GuestGuard>
        <Login />
      </GuestGuard>
    ),
  },
  {
    path: 'forgot-password',
    element: (
      <GuestGuard>
        <ForgotPassword />
      </GuestGuard>
    ),
  },
]

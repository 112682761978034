import PropTypes from 'prop-types'
import React from 'react'
import styled from '@emotion/styled'
import { Button as MaterialButton } from '@mui/material'

const StyledButton = styled(MaterialButton)(({ theme, type }) => ({
  color: theme.palette.text.primary,
  border: `1px solid ${theme.palette.text.primary}`,
  backgroundColor: 'transparent',
  borderRadius: 4,
  fontSize: 13,
  fontWeight: 500,
  cursor: 'pointer',
  '&:hover': {
    opacity: 0.7,
  },
}))
const Button = ({ children, ...props }) => {
  return <StyledButton {...props}>{children}</StyledButton>
}

Button.propTypes = {
  children: PropTypes.element.isRequired,
  variant: PropTypes.oneOf(['primary', 'secondary']),
  onClick: PropTypes.func.isRequired,
}

Button.defaultProps = {
  variant: 'primary',
}
export default Button

import 'react-perfect-scrollbar/dist/css/styles.css'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import 'nprogress/nprogress.css'
import { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'
import { HelmetProvider } from 'react-helmet-async'
import { BrowserRouter } from 'react-router-dom'
import { Provider as ReduxProvider } from 'react-redux'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import StyledEngineProvider from '@mui/material/StyledEngineProvider'
import App from './App'
import { SettingsProvider } from './contexts/SettingsContext'
import store from './store'
import { Amplify, Auth } from 'aws-amplify'
import { awsConfig } from './config'
import { AuthProvider } from 'torqcloud-ui'

const config = {
  Auth: {
    region: awsConfig.region,
    userPoolId: awsConfig.userPoolId,
    userPoolWebClientId: awsConfig.appClientId,
    cookieStorage: {
      domain: awsConfig.cookieDomain,
      secure: process.env.REACT_APP_ENV !== 'development',
    },
  },
}

Auth.configure(config)
Amplify.configure(config)

const container = document.getElementById('root')
const root = createRoot(container)

root.render(
  <StrictMode>
    <HelmetProvider>
      <title>{'adsff'}</title>
      <ReduxProvider store={store}>
        <StyledEngineProvider injectFirst>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <SettingsProvider>
              <BrowserRouter>
                <AuthProvider>
                  <App />
                </AuthProvider>
              </BrowserRouter>
            </SettingsProvider>
          </LocalizationProvider>
        </StyledEngineProvider>
      </ReduxProvider>
    </HelmetProvider>
  </StrictMode>
)

/**
 * Monitor API.
 */
import { request } from '../lib/http'
import { appList, appCreate, appDestroy } from '../constants/apiRouting'
import { apiConfig } from '../config'
import { Auth } from 'aws-amplify'
class AppApi {
  async list(data) {
    const cognitoUserSession = await Auth.currentSession()
    const idToken = cognitoUserSession?.getIdToken()?.getJwtToken()
    const headers = {
      Authorization: `Bearer ${idToken}`,
    }
    return request('GET', appList(), { data, headers }, apiConfig.API_VERSIONS.V1)
  }
  create(data) {
    return request('POST', appCreate(), { data, headers: { 'Content-Type': 'multipart/form-data' } }, apiConfig.API_VERSIONS.V1)
  }
  destroy(appId) {
    return request('DELETE', appDestroy(appId), {}, apiConfig.API_VERSIONS.V1)
  }
}

export const appsApi = new AppApi()

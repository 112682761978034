import { withStyles } from '@mui/styles'

const styles = () => ({
  root: {
    width: '52px',
    height: 'auto',
  },
})

const Logo = ({ classes, ...props }) => <img src="/img/ib-logo.svg" alt="IntelliBridge" className={classes.root} {...props} />

export default withStyles(styles)(Logo)

import { Box, Container } from '@mui/material'
import PropTypes from 'prop-types'

const PageContainer = ({ settings, children }) => {
  return (
    <Box
      sx={{
        backgroundColor: 'background.default',
        minHeight: '100%',
        py: 0,
      }}
    >
      <Container maxWidth={settings?.compact ? 'xl' : false}>{children}</Container>
    </Box>
  )
}

PageContainer.propTypes = {
  children: PropTypes.node.isRequired,
}

export default PageContainer

import { useRef, useState } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { Box, ButtonBase, Divider, ListItemText, MenuItem, Popover, Typography } from '@mui/material'
import AccountDropdownMenu from './AccountDropdown'
import styled from '@emotion/styled'
import toast from 'react-hot-toast'
import useAuth from '../../../hooks/useAuth'
import { useNavigate } from 'react-router'
import Link from '@mui/material/Link'

const PopoverStyled = styled(Popover)`
  display: inline-flex;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  .util-menu {
    margin-top: 0;
    padding: 16px;

    a {
      padding: 8px 0;
    }
  }
  .secondary-link {
    margin-left: 16px;
  }

  .menu-link h6::after {
    content: url('img/icon/navigate_next.svg');
    width: 12px;
    height: 12px;
    top: 10px;
    padding-left: 4px;
    position: absolute;
  }

  .log-out-link h6::after {
    content: url('img/icon/logout.svg');
    width: 12px;
    height: 12px;
    top: 8px;
    position: absolute;
    padding-left: 10px;
  }
`

const AccountDropdown = ({ userSettingsUrl }) => {
  const anchorRef = useRef(null)
  const { logout } = useAuth()
  const [open, setOpen] = useState(false)
  const navigate = useNavigate()

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleLogout = async () => {
    try {
      handleClose()
      await logout()
      navigate('/')
    } catch (err) {
      toast.error('Unable to logout.')
    }
  }

  return (
    <>
      <AccountDropdownMenu component={ButtonBase} onClick={handleOpen} customRef={anchorRef} />
      <PopoverStyled
        anchorEl={anchorRef.current}
        anchorOrigin={{
          horizontal: 'center',
          vertical: 'bottom',
        }}
        keepMounted
        onClose={handleClose}
        open={open}
        PaperProps={{
          sx: { width: 240 },
        }}
      >
        <Divider />
        <Box m={0} p={4}>
          <Link href={userSettingsUrl} sx={{ textDecoration: 'none' }}>
            <MenuItem className="menu-link">
              <ListItemText
                primary={
                  <Typography color="textPrimary" variant="subtitle2">
                    Settings
                  </Typography>
                }
              />
            </MenuItem>
          </Link>
          <MenuItem className="menu-link log-out-link" onClick={handleLogout}>
            <ListItemText
              primary={
                <Typography color="textPrimary" variant="subtitle2">
                  Logout
                </Typography>
              }
            />
          </MenuItem>
        </Box>
      </PopoverStyled>
    </>
  )
}

export default AccountDropdown

import AccountDropdown from '../components/layouts/AccountDropdown'
import { Box } from '@mui/material'
import styled from '@emotion/styled'
import { PropTypes } from 'prop-types'

const HeaderWrapper = styled('div')`
  display: flex;
  padding: 0 0 24px 0;
  align-items: center;
  justify-content: space-between;
`
const Header = ({ userSettingsUrl, SearchComponent }) => (
  <HeaderWrapper>
    <SearchComponent />
    <div></div>
    <Box
      sx={{
        alignItems: 'center',
        display: 'flex',
        position: 'relative',
        gap: '32px',
      }}
    >
      {/*<NotificationsPopover />*/}
      <AccountDropdown userSettingsUrl={userSettingsUrl} />
    </Box>
  </HeaderWrapper>
)

Header.propTypes = {
  userSettingsUrl: PropTypes.string,
  SearchComponent: PropTypes.elementType,
}

Header.defaultProps = {
  userSettingsUrl: null,
  SearchComponent: () => null,
}

export default Header

import PropTypes from 'prop-types'
import React from 'react'
import styled from '@emotion/styled'
import { Link as MaterialLink } from '@mui/material'

const StyledLink = styled(MaterialLink)(({ theme, type }) => ({
  color: theme.palette.link.primary,
  fontSize: '13px',
  fontWeight: 'normal',
}))
const Link = ({ children, ...props }) => {
  return <StyledLink {...props}>{children}</StyledLink>
}

Link.propTypes = {
  children: PropTypes.element.isRequired,
  href: PropTypes.string,
  onClick: PropTypes.func,
}
export default Link

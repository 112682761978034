import { useEffect, useState } from 'react'
import { appsApi } from '../../api/apps'

const useDataApps = () => {
  const [apps, setApps] = useState([])
  useEffect(() => {
    const loadDataApps = async () => {
      const apps = await appsApi.list()
      setApps(apps)
    }
    loadDataApps()
  }, [])

  return apps
}

export default useDataApps

import { combineReducers } from '@reduxjs/toolkit'
import { reducer as appsReducer } from '../slices/apps'
import sidebarReducer from './sidebarReducer'

const rootReducer = combineReducers({
  apps: appsReducer,
  sidebar: sidebarReducer,
})

export default rootReducer

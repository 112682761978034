/**
 * This is slice that fetches a list of monitors from an API.
 */

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { get } from 'lodash'
import { appsApi } from '../api/apps'
import objFromArray from '../utils/objFromArray'
import { defaultInitialState } from '../utils/reducerInitialState'
import { reducerStatus } from '../constants/reducer'

const initialState = defaultInitialState({
  results: {
    byId: {},
    allIds: [],
  },
})

export const appList = createAsyncThunk('app/list', async data => {
  return await appsApi.list(data)
})

export const appCreate = createAsyncThunk('app/create', async data => {
  return await appsApi.create(data)
})

export const appDestroy = createAsyncThunk('app/destroy', async appId => {
  return await appsApi.destroy(appId)
})

const slice = createSlice({
  name: 'app',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      // List
      .addCase(appList.pending, (state, action) => {
        state.status = reducerStatus.loading
      })
      .addCase(appList.fulfilled, (state, action) => {
        state.status = reducerStatus.succeeded
        const results = get(action, 'payload', [])
        state.results.byId = objFromArray(results)
        state.results.allIds = results.map(r => r.id)
      })
      .addCase(appList.rejected, (state, action) => {
        state.status = reducerStatus.failed
        state.error = action.error.message
      })

      // Create
      .addCase(appCreate.pending, (state, action) => {
        state.status = reducerStatus.loading
      })
      .addCase(appCreate.fulfilled, (state, action) => {
        state.status = reducerStatus.succeeded
        const result = get(action, 'payload', {})
        state.results.byId[result.id] = result
        state.results.allIds = [result.id, ...state.results.allIds]
      })
      .addCase(appCreate.rejected, (state, action) => {
        state.status = reducerStatus.failed
        state.error = action.error.message
      })

      // Destroy
      .addCase(appDestroy.pending, (state, action) => {
        state.status = reducerStatus.loading
      })
      .addCase(appDestroy.fulfilled, (state, action) => {
        state.status = reducerStatus.succeeded
        const { arg: appId } = action.meta
        delete state.results.byId[appId]
        state.results.allIds = state.results.allIds.filter(id => id !== appId)
      })
      .addCase(appDestroy.rejected, (state, action) => {
        state.status = reducerStatus.failed
        state.error = action.error.message
      })
  },
})

export const { reducer } = slice

import { useState } from 'react'
import { Outlet } from 'react-router-dom'
import { experimentalStyled } from '@mui/material/styles'
import { Header } from 'torqcloud-ui'
import DashboardSidebar from './DashboardSidebar'
import MenuIcon from '@mui/icons-material/Menu'
import { useDispatch, useSelector } from 'react-redux'
import { SHOW_SIDEBAR } from '../../store/actions'

const DashboardLayoutRoot = experimentalStyled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  display: 'flex',
  height: '100%',
  overflow: 'hidden',
  width: '100%',
}))

const DashboardLayoutWrapper = experimentalStyled('div')(({ theme }) => ({
  display: 'flex',
  flex: '1 1 auto',
  overflow: 'hidden',
  paddingTop: '1rem',
}))

const DashboardLayoutContainer = experimentalStyled('div')(theme => ({
  display: 'flex',
  flex: '1 1 auto',
  overflow: 'hidden',
}))

const DashboardLayoutContent = experimentalStyled('div')({
  flex: '1 1 auto',
  height: '100%',
  overflow: 'auto',
  position: 'relative',
  WebkitOverflowScrolling: 'touch',
  padding: '0.4rem 1.4rem 0 1.4rem',
})

const DashboardLayout = () => {
  const dispatch = useDispatch()
  const sidebar = useSelector(state => {
    return state.sidebar
  })

  const setIsSidebarMobileOpen = isOpen => {
    dispatch({ type: SHOW_SIDEBAR, show: isOpen })
  }

  return (
    <DashboardLayoutRoot>
      <MenuIcon sx={{ position: 'absolute', left: 10, top: 10, cursor: 'pointer' }} onClick={() => setIsSidebarMobileOpen(true)} />
      <DashboardSidebar openMobile={sidebar.show} onMobileClose={() => setIsSidebarMobileOpen(false)} />
      <DashboardLayoutWrapper>
        <DashboardLayoutContainer>
          <DashboardLayoutContent>
            <Header userSettingsUrl={'/account'} />
            <Outlet />
          </DashboardLayoutContent>
        </DashboardLayoutContainer>
      </DashboardLayoutWrapper>
    </DashboardLayoutRoot>
  )
}

export default DashboardLayout

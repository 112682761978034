import { Suspense, lazy } from 'react'
import { Navigate } from 'react-router-dom'
import DashboardLayoutWithSidebar from './components/layouts/DashboardLayoutWithSidebar'
import LoadingScreen from './components/LoadingScreen'
import MainLayout from './components/layouts/MainLayout'
import { apps } from './constants/routing'
import { Register, AuthGuard, DEFAULT_ROUTES, GuestGuard } from 'torqcloud-ui'
import { apiConfig } from './config'

const Loadable = Component => props =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  )

// Account pages
const Account = Loadable(lazy(() => import('./pages/Account')))

// Some app pages
const Apps = Loadable(lazy(() => import('./pages/Apps')))
const BlueForceRedForce = Loadable(lazy(() => import('./pages/analytics/BlueForceRedForce')))
const UnitReadiness = Loadable(lazy(() => import('./pages/analytics/UnitReadiness')))
const Map = Loadable(lazy(() => import('./pages/analytics/Map')))

// TorqSecure pages
const TorqSecure = Loadable(lazy(() => import('./pages/torqSecure/TorqSecure')))
const AOlytics = Loadable(lazy(() => import('./pages/torqSecure/AOlytics')))

// Error pages
const NotFound = Loadable(lazy(() => import('./pages/NotFound')))

const routes = [
  {
    path: 'apps',
    element: (
      <AuthGuard>
        <DashboardLayoutWithSidebar />
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: <Apps />,
      },
    ],
  },
  {
    path: 'analytics',
    element: (
      <AuthGuard>
        <DashboardLayoutWithSidebar />
      </AuthGuard>
    ),
    children: [
      {
        path: 'blue-force-red-force',
        element: <BlueForceRedForce />,
      },
      {
        path: 'unit-readiness',
        element: <UnitReadiness />,
      },
      {
        path: 'map',
        element: <Map />,
      },
    ],
  },
  {
    path: 'torq-secure',
    element: (
      <AuthGuard>
        <DashboardLayoutWithSidebar />
      </AuthGuard>
    ),
    children: [
      {
        path: 'sbom-security',
        element: <TorqSecure />,
      },
      {
        path: 'aolytics',
        element: <AOlytics />,
      },
    ],
  },
  {
    path: 'account',
    element: (
      <AuthGuard>
        <DashboardLayoutWithSidebar />
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: <Account />,
      },
    ],
  },
  {
    path: '*',
    element: <MainLayout />,
    children: [
      {
        path: '',
        element: <Navigate to={apps()} />,
      },
      ...DEFAULT_ROUTES,
      {
        path: 'register',
        element: (
          <GuestGuard>
            <Register torqCloudBaseUrl={apiConfig.API_BASE} />
          </GuestGuard>
        ),
      },
      {
        path: '*',
        element: <NotFound />,
      },
    ],
  },
]

export default routes

import { createContext, useEffect, useReducer } from 'react'
import PropTypes from 'prop-types'
import { Auth } from 'aws-amplify'

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: {
    name: 'Demo User',
  },
}

const reducer = (state, action) => {
  if (action.type === 'AUTH_STATE_CHANGED') {
    const { isAuthenticated, user } = action.payload

    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
    }
  }

  return state
}

export const AuthContext = createContext({
  ...initialState,
  platform: 'Cognito',
  signInWithGoogle: () => Promise.resolve(),
  signUp: () => Promise.resolve(),
  changeCognitoAuthUser: () => {},
  logout: () => Promise.resolve(),
})

export const AuthProvider = props => {
  const { children } = props
  const [state, dispatch] = useReducer(reducer, initialState)
  useEffect(() => {
    const loadCurrentCognitoUser = async () => {
      try {
        const user = await Auth.currentAuthenticatedUser()
        if (user) {
          changeCognitoAuthUser(user)
        } else {
          dispatch({
            type: 'AUTH_STATE_CHANGED',
            payload: {
              ...initialState,
            },
          })
        }
      } catch (err) {
        dispatch({
          type: 'AUTH_STATE_CHANGED',
          payload: {
            ...initialState,
          },
        })
      }
    }
    loadCurrentCognitoUser()
  }, [dispatch])

  const signInWithGoogle = async () => {
    try {
      await Auth.federatedSignIn({ provider: 'Google' })
    } catch (error) {
      console.error('Error signing in with Google:', error)
    }
  }

  const logout = async () => {
    await Auth.signOut()
    dispatch({
      type: 'AUTH_STATE_CHANGED',
      payload: {
        ...initialState,
      },
    })
  }

  const changeCognitoAuthUser = async user => {
    window.analytics.identify(user.attributes?.email, {
      email: user.attributes?.email,
    })
    dispatch({
      type: 'AUTH_STATE_CHANGED',
      payload: {
        isAuthenticated: true,
        user: {
          id: user.username,
          email: user.attributes.email,
          name: user.displayName || user.attributes.email,
          account: {
            subscription: {
              plan: {
                name: 'Trial',
              },
            },
          },
        },
      },
    })
  }
  return (
    <AuthContext.Provider
      value={{
        ...state,
        signInWithGoogle,
        changeCognitoAuthUser,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

import { NavLink as RouterLink } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Box, Button, Collapse, ListItem } from '@mui/material'
import styled from '@emotion/styled'

const StyledButton = styled(Button)`
  .MuiButton-startIcon {
    color: ${({ theme }) => theme.sidebar.iconColor};
  }
`
const SubNavItem = props => {
  const { active, childItems, depth, icon, info, path, title } = props

  let paddingLeft = 16

  if (depth > 0) {
    paddingLeft = 32 + 8 * depth
  }

  // Leaf
  return (
    <ListItem
      disableGutters
      sx={{
        display: 'flex',
        py: 0,
      }}
    >
      <StyledButton
        component={path && RouterLink}
        startIcon={icon}
        sx={{
          color: 'text.secondary',
          fontWeight: 'fontWeightMedium',
          justifyContent: 'flex-start',
          textAlign: 'left',
          pl: `${paddingLeft}px`,
          pr: '8px',
          py: '12px',
          textTransform: 'none',
          lineHeight: '1rem',
          width: '100%',
          ...(active && {
            color: 'primary.main',
            fontWeight: 'fontWeightBold',
            '& svg': {
              color: 'primary.main',
            },
          }),
        }}
        variant="text"
        to={path}
      >
        <Box sx={{ marginLeft: '1.6rem', flexGrow: 1, fontWeight: '300', color: theme => theme.typography.textColor.primary }}>{title}</Box>
        {info}
      </StyledButton>
    </ListItem>
  )
}

SubNavItem.propTypes = {
  active: PropTypes.bool,
  children: PropTypes.node,
  depth: PropTypes.number.isRequired,
  icon: PropTypes.node,
  info: PropTypes.node,
  path: PropTypes.string,
  title: PropTypes.string.isRequired,
}

SubNavItem.defaultProps = {
  active: false,
}

export default SubNavItem

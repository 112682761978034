import PropTypes from 'prop-types'
import React from 'react'
import { experimentalStyled } from '@mui/material/styles'

const CardContainer = experimentalStyled('div')(({ theme }) => ({
  background: 'linear-gradient(180deg, #313E4B 0%, #1F2934 100%)',
  borderRadius: '12px',
  borderSpacing: '0',
  fontFamily: theme.fonts.primary,
  padding: '16px',
}))

const Card = ({ children, sx, className }) => {
  return (
    <CardContainer sx={sx} className={className}>
      {children}
    </CardContainer>
  )
}

Card.propTypes = {
  children: PropTypes.element.isRequired,
  sx: PropTypes.object,
  className: PropTypes.string,
}

export default Card

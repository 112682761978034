import axios from 'axios'

const apiClient = (torqCloudBaseUrl: string) =>
  axios.create({
    baseURL: `${torqCloudBaseUrl}/api`,
    headers: {
      'Content-type': 'application/json',
    },
  })

export default apiClient

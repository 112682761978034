import createSvgIcon from '@mui/material/utils/createSvgIcon'

const Filter = createSvgIcon(
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.279 3.62787C11.279 3.28109 10.9978 2.99997 10.6511 2.99997C10.3043 2.99997 10.0232 3.28109 10.0232 3.62787V5.30226C10.0232 5.64903 10.3043 5.93015 10.6511 5.93015C10.9978 5.93015 11.279 5.64903 11.279 5.30226V3.62787ZM14.6277 6.13945C14.6277 5.79267 14.3466 5.51155 13.9998 5.51155C13.6531 5.51155 13.372 5.79267 13.372 6.13945V7.81384C13.372 8.16062 13.6531 8.44174 13.9998 8.44174C14.3466 8.44174 14.6277 8.16062 14.6277 7.81384V6.13945ZM8.31913 7.57826C8.65141 7.47904 8.84034 7.12924 8.74113 6.79696C8.64192 6.46468 8.29212 6.27574 7.95984 6.37496C7.15381 6.61563 6.44813 6.93294 5.92993 7.3262C5.42021 7.71302 5 8.24876 5 8.92412C5 12.1336 6.68846 14.9437 9.18598 16.2559V18.7065C9.18598 20.4158 10.9919 21.5135 12.5164 20.7564L14.1907 19.9248C14.9701 19.5378 15.4649 18.7449 15.4649 17.8749V16.2559C17.9625 14.9437 19.6509 12.1336 19.6509 8.92412C19.6509 8.24876 19.2307 7.71302 18.721 7.3262C18.2028 6.93294 17.4971 6.61563 16.6911 6.37496C16.3588 6.27574 16.009 6.46468 15.9098 6.79696C15.8106 7.12924 15.9995 7.47904 16.3318 7.57826C17.0569 7.79477 17.607 8.05731 17.9618 8.32655C18.3251 8.60225 18.3951 8.81167 18.3951 8.92412C18.3951 9.02281 18.3436 9.1933 18.0817 9.42393C17.8208 9.65368 17.4073 9.88923 16.8422 10.0997C15.7168 10.5189 14.12 10.7909 12.3255 10.7909C10.5309 10.7909 8.93415 10.5189 7.80873 10.0997C7.24364 9.88923 6.83009 9.65368 6.5692 9.42393C6.3073 9.1933 6.25579 9.02281 6.25579 8.92412C6.25579 8.81167 6.32579 8.60225 6.68908 8.32655C7.04387 8.05731 7.59399 7.79477 8.31913 7.57826ZM10.6511 7.18594C10.9978 7.18594 11.279 7.46706 11.279 7.81384V8.65104C11.279 8.99781 10.9978 9.27893 10.6511 9.27893C10.3043 9.27893 10.0232 8.99781 10.0232 8.65104V7.81384C10.0232 7.46706 10.3043 7.18594 10.6511 7.18594Z"
      fill="#DDB900"
    />
  </svg>,
  'Filter'
)

export default Filter

import { DashboardSidebar, SubNavItem } from 'torqcloud-ui'
import {
  account,
  torqFlow,
  torqData,
  torqNLP,
  torqSecure,
  aOlytics,
  sbomSecurity,
  torqAgents,
  analyticsBlueForceRedForce,
  analyticsMap,
  analyticsUnitReadiness,
} from '../../constants/routing'
import User from '../../icons/User'
import Chat from '../../icons/Chat'
import ShieldWarning from '../../icons/ShieldWarning'
import Analystics from '../../icons/Analystics'
import Filter from '../../icons/Filter'
import Loading from '../../icons/Loading'
import Share from '../../icons/Share'
import { GetAppPermissions, APP_TYPES } from '../../utils/permissions'

const DashboardSidebarTorqCloud = props => {
  const appPermissions = GetAppPermissions()

  const sections = [
    {
      title: 'Modules',
      items: [
        ...(appPermissions[APP_TYPES.TORQ_FLOW]
          ? [
              {
                title: 'TorqFlow',
                path: torqFlow(),
                icon: <Share fontSize="small" />,
                childItems: [],
              },
            ]
          : []),

        ...(appPermissions[APP_TYPES.TORQ_DATA]
          ? [
              {
                title: 'TorqData',
                path: torqData(),
                icon: <Loading fontSize="small" />,
                childItems: [],
              },
            ]
          : []),

        {
          title: 'TorqNLP',
          path: torqNLP(),
          icon: <Filter fontSize="small" />,
          childItems: [],
        },
        {
          title: 'TorqAnalytics',
          path: analyticsBlueForceRedForce(),
          icon: <Analystics fontSize="small" />,
          childItems: [
            <SubNavItem key={'bgf'} path={analyticsBlueForceRedForce()} title={'Blue Force / Red Force'} />,
            <SubNavItem key={'bgf'} path={analyticsUnitReadiness()} title={'Unit Readiness'} />,
            <SubNavItem key={'bgf'} path={analyticsMap()} title={'Map'} />,
          ],
        },
        {
          title: 'TorqSecure',
          path: sbomSecurity(),
          icon: <ShieldWarning fontSize="small" />,
          childItems: [
            <SubNavItem key={'bgf'} path={sbomSecurity()} title={'SBOM security'} />,
            <SubNavItem key={'bgf'} path={aOlytics()} target="_blank" title={'Aolytix'} />,
          ],
        },

        ...(appPermissions[APP_TYPES.TORQ_AGENT]
          ? [
              {
                title: 'TorqAgent',
                path: torqAgents(),
                icon: <Chat fontSize="small" />,
                childItems: [],
              },
            ]
          : []),
      ],
    },
  ]

  return <DashboardSidebar sections={sections} logoLinkUrl="/" onMobileClose={props.onMobileClose} openMobile={props.openMobile} />
}

export default DashboardSidebarTorqCloud

import PropTypes from 'prop-types'
import { matchPath } from 'react-router-dom'
import { Box, List, Typography } from '@mui/material'
import NavItem from './NavItem'

const renderNavItems = ({ depth = 0, items, pathname }) => (
  <List disablePadding>
    {items.reduce(
      // eslint-disable-next-line no-use-before-define
      (acc, item) =>
        reduceChildRoutes({
          acc,
          item,
          pathname,
          depth,
        }),
      []
    )}
  </List>
)

const reduceChildRoutes = ({ acc, pathname, item, depth }) => {
  const key = `${item.title}-${depth}`
  const exactMatch = item.path
    ? !!matchPath(
        {
          path: item.path,
          end: true,
        },
        pathname
      )
    : false

  if (item.childItems) {
    const partialMatch = item.path
      ? !!matchPath(
          {
            path: item.path,
            end: false,
          },
          pathname
        )
      : false

    acc.push(
      <NavItem
        active={partialMatch}
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        open={partialMatch}
        path={item.path}
        title={item.title}
        childItems={item.childItems}
      >
        {renderNavItems({
          depth: depth + 1,
          items: item.childItems,
          pathname,
        })}
      </NavItem>
    )
  } else {
    acc.push(<NavItem active={exactMatch} depth={depth} icon={item.icon} info={item.info} key={key} path={item.path} title={item.title} />)
  }

  return acc
}

const NavSection = props => {
  const { items, pathname, title, ...other } = props

  return (
    <Box display="flex" textAlign="center" flexDirection="column">
      <Typography
        sx={{
          textTransform: 'uppercase',
          fontWeight: '500',
          fontSize: '12px',
          opacity: '0.7',
          letterSpacing: '2.04px',
          color: theme => theme.typography.textColor.primary,
        }}
      >
        {props.title}
      </Typography>
      <List {...other}>
        {renderNavItems({
          items,
          pathname,
        })}
      </List>
    </Box>
  )
}

NavSection.propTypes = {
  items: PropTypes.array,
  pathname: PropTypes.string,
  title: PropTypes.string,
}

export default NavSection

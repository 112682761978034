import { useState } from 'react'
import { NavLink as RouterLink } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Box, Button, Collapse, ListItem, Link } from '@mui/material'
import ChevronDownIcon from '../../icons/ChevronDown'
import ChevronRightIcon from '../../icons/ChevronRight'
import { isPathExternal } from '../../utils/helpers'
import styled from '@emotion/styled'

const StyledButton = styled(Button)`
  .MuiButton-startIcon {
    color: ${({ theme }) => theme.sidebar.iconColor};
  }
`
const NavItem = props => {
  const { active, childItems, depth, icon, info, open: openProp, path, title, ...other } = props
  const [open, setOpen] = useState(openProp)

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen)
  }

  let paddingLeft = 16

  if (depth > 0) {
    paddingLeft = 32 + 8 * depth
  }

  // Branch
  if (childItems && childItems.length > 0) {
    return (
      <ListItem
        disableGutters
        sx={{
          display: 'block',
          py: 0,
        }}
        {...other}
      >
        <StyledButton
          endIcon={!open ? <ChevronRightIcon fontSize="small" /> : <ChevronDownIcon fontSize="small" />}
          onClick={handleToggle}
          startIcon={icon}
          sx={{
            color: 'text.secondary',
            fontWeight: 'fontWeightMedium',
            justifyContent: 'flex-start',
            pl: `${paddingLeft}px`,
            pr: '8px',
            py: '12px',
            textAlign: 'left',
            textTransform: 'none',
            width: '100%',
          }}
          variant="text"
        >
          <Box sx={{ flexGrow: 1, fontWeight: 'bold', color: theme => theme.typography.textColor.primary }}>{title}</Box>
          {info}
        </StyledButton>
        <Collapse in={open}>{childItems}</Collapse>
      </ListItem>
    )
  }

  const styles = {
    color: 'text.secondary',
    fontWeight: 'fontWeightMedium',
    justifyContent: 'flex-start',
    textAlign: 'left',
    pl: `${paddingLeft}px`,
    pr: '8px',
    py: '12px',
    textTransform: 'none',
    width: '100%',
    ...(active && {
      color: 'primary.main',
      fontWeight: 'fontWeightBold',
      '& svg': {
        color: 'primary.main',
      },
    }),
  }
  const boxStyles = {
    flexGrow: 1,
    fontWeight: 'bold',
    color: theme => theme.typography.textColor.primary,
  }

  // Leaf
  return (
    <ListItem
      disableGutters
      sx={{
        display: 'flex',
        py: 0,
      }}
    >
      {isPathExternal(path) ? (
        <StyledButton component={path && Link} startIcon={icon} sx={styles} variant="text" href={path} target="_blank">
          <Box sx={boxStyles}>{title}</Box>
          {info}
        </StyledButton>
      ) : (
        <StyledButton component={path && RouterLink} startIcon={icon} sx={styles} variant="text" to={path}>
          <Box sx={boxStyles}>{title}</Box>
          {info}
        </StyledButton>
      )}
    </ListItem>
  )
}

NavItem.propTypes = {
  active: PropTypes.bool,
  children: PropTypes.node,
  depth: PropTypes.number.isRequired,
  icon: PropTypes.node,
  info: PropTypes.node,
  open: PropTypes.bool,
  path: PropTypes.string,
  title: PropTypes.string.isRequired,
}

NavItem.defaultProps = {
  active: false,
  open: false,
}

export default NavItem

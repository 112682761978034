import useDataApps from '../hooks/data/useDataApps'

export const APP_TYPES = {
  TORQ_AGENT: 'torqagent',
  TORQ_DATA: 'torqdata',
  TORQ_FLOW: 'torqflow',
}

export const GetAppPermissions = () => {
  const apps = useDataApps()
  const appTypes = apps.map(app => app.appType) // Extract appType from each app

  // Helper function to check if user has access to a specific app
  const hasAccessToApp = appName => appTypes.includes(appName)

  return {
    [APP_TYPES.TORQ_AGENT]: hasAccessToApp(APP_TYPES.TORQ_AGENT),
    [APP_TYPES.TORQ_DATA]: hasAccessToApp(APP_TYPES.TORQ_DATA),
    [APP_TYPES.TORQ_FLOW]: hasAccessToApp(APP_TYPES.TORQ_FLOW),
  }
}

import axios from 'axios'
import qs from 'query-string'
import { camelCase, get, mapKeys, snakeCase } from 'lodash'
import { APIError } from './errors'
import recursiveFunc from '../utils/recursiveFunc'
import { apiConfig } from '../config'
import { Auth } from 'aws-amplify'
import toast from 'react-hot-toast'

export const REQUEST_TIMEOUT_MS = 60000
const SUCCESS_CODES = [200, 201, 202, 204]

const camelCaseIfNotId = v => (v.includes('-') ? v : camelCase(v))

const extractResult = response => {
  const code = response.status
  const body = response.data

  if (SUCCESS_CODES.includes(code)) {
    // convert keys to camelCase and return
    return recursiveFunc(mapKeys)(body, (v, k) => camelCaseIfNotId(k))
  }
  throw new APIError(code, body.error)
}

export const serverUrl = (path, version = null) => {
  const base = `${apiConfig.API_BASE}/api`
  let url = null
  if (base) {
    // the url is relative unless a base is configured
    url = `${base}/${path}/`
  }

  if (version) {
    url = `${base}/${version}/${path}/`
  }
  return url
}

export const request = (method, url, options = {}, apiVersion = null) =>
  new Promise(async (resolve, reject) => {
    if (!url) {
      return reject(new APIError(400, 'Request url is a required field'))
    }
    if (!method) {
      return reject(new APIError(400, 'Request method is a required field'))
    }

    const cognitoUserSession = await Auth.currentSession()
    const idToken = cognitoUserSession?.getIdToken()?.getJwtToken()
    const headers = options.headers || {}
    if (idToken) {
      headers.Authorization = `Bearer ${idToken}`
    }
    if (get(options, 'data')) {
      options.data = recursiveFunc(mapKeys)(options.data, (v, k) => snakeCase(k))
    }

    if (get(options, 'params')) {
      options.params = recursiveFunc(mapKeys)(options.params, (v, k) => snakeCase(k))
    }

    const reqOptions = {
      timeout: REQUEST_TIMEOUT_MS,
      url: url.startsWith('http') ? url : serverUrl(url, apiVersion),
      paramsSerializer: { serialize: params => qs.stringify(params) },
      headers,
      method,
      ...options,
    }
    return axios
      .request(reqOptions)
      .then(extractResult)
      .then(resolve)
      .catch(response => {
        if (get(response, ['response', 'status']) === 401) {
          // hand un authenticated error
        }
        toast.error(response?.message || response)
        reject(response.message)
      })
  })

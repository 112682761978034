import * as React from 'react'
import { Avatar, Box, Typography } from '@mui/material'
import useAuth from '../../../hooks/useAuth'

export default function AccountDropdown({ onClick, component, customRef }) {
  const { user } = useAuth()

  return (
    <Box
      sx={{
        alignItems: 'center',
        display: 'flex',
      }}
      onClick={onClick}
      component={component}
      ref={customRef}
    >
      <Box
        sx={{
          ml: 1,
        }}
      ></Box>

      <Box sx={{ textAlign: 'left' }}>
        <Typography color="textPrimary" variant="subtitle2">
          Hello, {user.name}!
        </Typography>
      </Box>
      <Avatar
        src={user.avatar}
        sx={{
          cursor: 'pointer',
          height: 40,
          width: 40,
          ml: 2,
        }}
      />
    </Box>
  )
}

import { Box } from '@mui/material'
import { COLORS } from '../../constants/theme'

const ContentBox = ({ children, sx }) => (
  <Box
    sx={{
      background: COLORS.GRADIENT_BG,
      position: 'relative',
      overflow: 'hidden',
      borderTopLeftRadius: '12px',
      marginRight: '-1.4rem',
      ...(sx || {}),
    }}
  >
    {children}
  </Box>
)

export default ContentBox

import { useLayoutEffect } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'
import { Box, Card, CardContent, Container, Link, Typography } from '@mui/material'
import { ForgotPasswordCognito } from '../../components/authentication/forgot-password'
import Logo from '../../components/Logo'
import IBLogo from '../../components/LogoIntellibridge'
import gtm from '../../lib/gtm'
import { appConfig } from '../../config'
import { register } from '../../constants/routing'
import {
  ArchAnimateContainer,
  ContentAnimateContainer,
  LogoWrapper,
  StatusAnimated,
  WrapperStyled,
} from '../../components/authentication/common'

const ForgotPassword = () => {
  useLayoutEffect(() => {
    gtm.push({ event: 'page_view' })
  }, [])

  return (
    <WrapperStyled>
      <Helmet>
        <title>{`Forgot Password | ${appConfig.APP_NAME}`}</title>
      </Helmet>
      <ArchAnimateContainer />
      <ContentAnimateContainer>
        <Container
          maxWidth="sm"
          sx={{
            py: '80px',
            backgroundColor: 'primary.default',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              mb: 8,
            }}
          ></Box>
          <Card>
            <CardContent
              sx={{
                display: 'flex',
                flexDirection: 'column',
                p: 4,
              }}
            >
              <LogoWrapper>
                <RouterLink to="/">
                  <Logo className="logo" />
                </RouterLink>
              </LogoWrapper>
              <Box
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <div>
                  <Typography color="textPrimary" gutterBottom variant="h4">
                    Forgot Password
                  </Typography>
                </div>
              </Box>
              <Box
                sx={{
                  flexGrow: 1,
                  mt: 3,
                }}
              >
                <ForgotPasswordCognito />
              </Box>
            </CardContent>
          </Card>
          <div style={{ marginTop: 24 }}>
            <LogoWrapper>
              <IBLogo />
            </LogoWrapper>
          </div>
          <StatusAnimated />
        </Container>
      </ContentAnimateContainer>
    </WrapperStyled>
  )
}

export default ForgotPassword

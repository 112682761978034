export const amplifyConfig = {
  aws_project_region: process.env.REACT_APP_AWS_PROJECT_REGION,
  aws_cognito_identity_pool_id: process.env.REACT_APP_AWS_COGNITO_IDENTITY_POOL_ID,
  aws_cognito_region: process.env.REACT_APP_AWS_COGNITO_REGION,
  aws_user_pools_id: process.env.REACT_APP_AWS_USER_POOLS_ID,
  aws_user_pools_web_client_id: process.env.REACT_APP_AWS_USER_POOLS_WEB_CLIENT_ID,
}

export const auth0Config = {
  client_id: process.env.REACT_APP_AUTH0_CLIENT_ID,
  domain: process.env.REACT_APP_AUTH0_DOMAIN,
  audience: process.env.REACT_APP_AUTH0_AUDIENCE,
}

export const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
}

export const gtmConfig = {
  containerId: process.env.REACT_APP_GTM_CONTAINER_ID,
}

export const apiConfig = {
  API_BASE: process.env.REACT_APP_API_BASE,
  API_VERSIONS: {
    V1: 'v1',
  },
}

export const appConfig = {
  APP_NAME: process.env.REACT_APP_NAME,
}

export const awsConfig = {
  region: process.env.REACT_APP_COGNITO_REGION,
  userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
  appClientId: process.env.REACT_APP_COGNITO_APP_CLIENT_ID,
  cookieDomain: process.env.REACT_APP_COGNITO_COOKIE_DOMAIN,
}

export const torqFlowConfig = {
  baseUrl: process.env.REACT_APP_TORQFLOW_API_BASE_URL,
  authHeader: process.env.REACT_APP_TORQFLOW_AUTH_HEADER,
  appBaseUrl: process.env.REACT_APP_TORQFLOW_UI_BASE_URL,
}

export const torqAgentConfig = {
  baseUrl: process.env.REACT_APP_TORQAGENT_API_BASE_URL,
}
